import City from '../components/home/city'
import { useEffect, useState } from "react";

const Home = () => {
  useEffect(() => {
    fetch(
      "https://api.openweathermap.org/data/2.5/weather?q=mumbai&APPID=7c8a3f66b6e02bb59b207feb023b4010"
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [data, setData] = useState(null)

  return (
    <div>
      <City/>
    </div>
  );
};

export default Home;
