const Footer = () => {
  return (
    <div>
      <p className="text-center py-5 bg-blue-300">
        Copyright &copy; 2021 Rahul Gupta
      </p>
    </div>
  );
};

export default Footer;
