import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="flex bg-blue-300 items-center md:px-16 md:py-2">
      <div className="flex items-center text-2xl font-bold">
        React Weather Info
      </div>
      <div className="ml-auto space-x-5">
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
      </div>
    </div>
  );
};

export default Navbar;
