import { useEffect, useState } from "react";

const City = () => {
    let url = `https://api.openweathermap.org/data/2.5/weather`
    let api = `7c8a3f66b6e02bb59b207feb023b4010`
    
    const [city, setCity] = useState('mumbai')
    const [weatherData, setWeatherData] = useState(null)

    useEffect(() => {
        setWeatherData(null)
        getData();
    }, [city]);


    function getData() {
        fetch(
            `${url}?q=${city}&APPID=${api}`
        ).then((response) => response.json())
            .then((data) => {
                setWeatherData({
                    temp: parseFloat(data.main.temp - 273.15).toPrecision(4),
                    feels_like: parseFloat(data.main.feels_like - 273.15).toPrecision(),
                    temp_min: parseFloat(data.main.temp_min - 273.15).toPrecision(4),
                    temp_max: parseFloat(data.main.temp_max - 273.15).toPrecision(4),
                    pressure: data.main.pressure,
                    humidity: data.main.humidity + "%",
                    wind: data.wind,
                })
            })
            .catch((err) => {
                setWeatherData('error')
            });
    }

    return (
        <div className="container mx-auto">
            <div className="flex justify-around gap-1 text-white mb-8">
                <span onClick={() => setCity('mumbai')} className={`p-4 text-center flex-1 cursor-pointer mx-auto ${city == 'mumbai' ? 'bg-blue-500' : 'bg-green-500'}`}>Mumbai</span>
                <span onClick={() => setCity('new york')} className={`p-4 text-center flex-1 cursor-pointer mx-auto ${city == 'new york' ? 'bg-blue-500' : 'bg-green-500'}`}>New York</span>
                <span onClick={() => setCity('london')} className={`p-4 text-center flex-1 cursor-pointer mx-auto ${city == 'london' ? 'bg-blue-500' : 'bg-green-500'}`}>London</span>
            </div>
            {(() => {
                if (weatherData == null) {
                    return (
                        <p>Loading</p>
                    )
                } else if (weatherData == 'error') {
                    return (
                        <p>Error</p>
                    )
                } else {
                    return (
                        <div>
                            <div className="content mb-4 flex justify-around">
                                <div>
                                    <img src="https://img.icons8.com/color/48/000000/thermometer.png" />
                                    {weatherData.temp}°
                                </div>
                                <div>
                                    <img src="https://img.icons8.com/color/48/000000/wind.png" />
                                    {weatherData.wind.speed}
                                </div>
                                <div>
                                    <img
                                        src="https://img.icons8.com/color/48/000000/atmospheric-pressure.png"
                                    />
                                    {weatherData.pressure}
                                </div>
                            </div>
                            <div className="content flex justify-around">
                                <div>
                                    <p>Temperature : {weatherData.temp}°</p>
                                    <p>Temperature (Min) : {weatherData.temp_min}°</p>
                                    <p>Temperature (Max) : {weatherData.temp_max}°</p>
                                    <p>Feels Like : {weatherData.temp_max}°</p>
                                </div>
                                <div>
                                    <p>Wind Speed : {weatherData.wind.speed}</p>
                                    <p>Wind Direction : {weatherData.wind.deg}°</p>
                                </div>
                                <div>
                                    <p>pressure : {weatherData.pressure}</p>
                                    <p>humidity : {weatherData.humidity}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            })()}
        </div>
    );
};

export default City;
